<template>
  <div class="research-form-container">
    <lock-screen-loader v-if="enableLoader"/>
    <app-alert
      v-if="showAlert"
      :alertData="alertData"
      @dismissed="onAlertDismiss"
    />

    <mds-dialog
      v-model="showResearchDialog"
      :title="researchDialog.dialogTitle"
      :text="researchDialog.dialogText"
      @mds-dialog-dismissed="resetResearchDialog"
    >
      <template #mds-dialog-actions-right>
        <mds-button-container right-aligned>
          <mds-button
            type="button"
            variation="secondary"
            @click="handleDialogCancel"
          >
            {{ researchDialog.cancelBtnText }}
          </mds-button>
          <mds-button
            type="button"
            variation="primary"
            @click="handleDialogSuccess"
          >
            {{ researchDialog.successBtnText }}
          </mds-button>
        </mds-button-container>
      </template>
    </mds-dialog>

    <mds-layout-grid>
      <mds-row>
        <mds-col :cols="12">
          <div class="research-form-title">{{ formData.formTitle }}</div>
        </mds-col>
      </mds-row>

      <mds-row>
        <mds-col :cols="12" :cols-at-m="12" class="research-form-input-field">
          <mds-input v-bind="formData.title" @input="formData.title.error=false" v-model="formData.title.value" :disabled="isFormDisabled" />
        </mds-col>
      </mds-row>

      <mds-row>
        <mds-col :cols="12" :cols-at-m="12" class="research-form-input-field">
          <mds-textarea v-bind="formData.description" v-model="formData.description.value" :disabled="isFormDisabled" @input="formData.description.error=false" />
        </mds-col>
      </mds-row>

      <mds-row>
        <mds-col :cols="12" :cols-at-m="12" class="research-form-input-field">
          <mds-textarea v-bind="formData.introduction" v-model="formData.introduction.value" :disabled="isFormDisabled" @input="formData.introduction.error=false" />
        </mds-col>
      </mds-row>

      <mds-row>
        <mds-col :cols="12" :cols-at-m="12" class="research-form-input-field">
          <mds-textarea v-bind="formData.keyPoints" v-model="formData.keyPoints.value" :disabled="isFormDisabled" @input="formData.keyPoints.error=false" />
        </mds-col>
      </mds-row>

      <mds-row>
        <mds-col :cols="12" :cols-at-m="6" >
          <mds-fieldset class="research-form-input-field" >
            <mds-date-picker
              v-bind="formData.publishDate"
              v-model="formData.publishDate.value"
              :min-max-dates="{min: new Date(2000, 0, 1), max: new Date(2099, 8, 15)}"
              :date-format="{day:'2-digit', month: '2-digit'}"
              :disabled="isFormDisabled"
              onkeydown="return false"
              onpaste="return false"
              @input="formData.publishDate.error = false"
            ></mds-date-picker>
          </mds-fieldset>
        </mds-col>
        <mds-col :cols="12" :cols-at-m="6">
          <mds-fieldset class="research-form-input-field">
            <mds-select v-bind="formData.type" v-model="formData.type.value" :disabled="isFormDisabled" @input="formData.type.error=false"/>
          </mds-fieldset>
        </mds-col>
      </mds-row>

      <mds-row>
        <mds-col :cols="12" :cols-at-m="12" class="research-form-input-field">
          <mds-input
            v-bind="formData.researchFile"
            :value="formData.researchPaperUrl"
            :disabled="isFormDisabled"
          />
        </mds-col>
      </mds-row>
    </mds-layout-grid>

    <div class="research-form-note" v-html="this.formData.deleteNoteMessage" />

    <mds-button-container right-aligned class="research-form-action-btn">
      <mds-button variation="primary" :disabled="deleteDisabled" @click="onDelete">Delete</mds-button>
      <mds-button variation="primary" :disabled="publishDisabled"  @click="onPublish">Delete From Prod</mds-button>
    </mds-button-container>
  </div>
</template>

<script>
import MdsDialog from '@mds/dialog';
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import { MdsButton, MdsButtonContainer } from "@mds/button";
import MdsInput from "@mds/input";
import MdsFieldset from "@mds/fieldset";
import MdsSelect from "@mds/select";
import MdsTextarea from "@mds/textarea";
import cloneDeep from 'lodash/cloneDeep';
import researchFormConfig from '@/data/publishResearch.json';
import MdsDatePicker from '@mds/date-picker'
import LockScreenLoader from '@/components/Common/LockScreenLoader/LockScreenLoader.vue';
import AppAlert from '@/components/Common/AppAlert/AppAlert';
import {
  getResearchList,
  deleteResearchData,
  publishResearchData,
} from "@/services/researchService.js";
import { format } from 'date-fns';
import { sendMail } from "@/services/emailService.js"
import { mapGetters } from 'vuex';

export default {
  name: "ResearchForm",
  components: {
    MdsDialog,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsInput,
    MdsButton,
    MdsButtonContainer,
    MdsFieldset,
    MdsSelect,
    MdsTextarea,
    MdsDatePicker,
    LockScreenLoader,
    AppAlert
  },
  data() {
    return {
      formData: cloneDeep(researchFormConfig?.formConfig),
      deleteDisabled: false,
      publishDisabled: true,
      enableLoader: false,
      isFormDisabled: true,
      showAlert: false,
      alertData: {},
      dialogConfig: cloneDeep(researchFormConfig?.dialogConfig),
      researchDialog: {},
      showResearchDialog: false,
      emailConfig: cloneDeep(researchFormConfig?.emailConfig)
    };
  },
  computed: {
    ...mapGetters({
      userName: 'user/userName',
    })
  },
  created() {
    this.getRecentResearch();
  },
  methods: {
    async getRecentResearch() {
      this.enableLoader = true;
      await getResearchList()
        .then((res) => {
          if (res) {
            this.enableLoader = false;
            const keyPointsArray = res[0].keyPoints;
            this.formData.id = res[0].id;
            this.formData.title.value = res[0].title;
            this.formData.description.value = res[0].description;
            this.formData.status = res[0].status;
            this.formData.introduction.value = res[0].introduction;
            this.formData.keyPoints.value = keyPointsArray.length > 1 ? keyPointsArray.join("\n") : keyPointsArray[0];
            this.formData.publishDate.value = res[0].publishDate;
            this.formData.researchPaperUrl = res[0].researchPaperUrl;
            this.formData.type.value = res[0].type;
          } else {
            this.handelApiError();
          }
        })
        .finally(() => {
          this.enableLoader = false;
        })
    },
    onPublish(event) {
      this.researchDialog = this.dialogConfig?.publishDeleteResearch;
      this.showResearchDialog = true;
      event.preventDefault();
    },
    onDelete(event){
      this.researchDialog = this.dialogConfig?.deleteResearch;
      this.showResearchDialog = true;
      event.preventDefault();
    },
    handleDialogCancel() {
      this.showResearchDialog = false;
    },
    handleDialogSuccess() {
      switch (this.researchDialog?.action) {
        case 'delete':
          this.deleteResearch();
          break;
        case 'publish':
          this.publishResearch();
          break;
        default:
          break;
      }
    },
    async deleteResearch(){
      this.showResearchDialog = false;
      this.enableLoader = true;
      const postData = {
        id: this.formData.id,
        fileName: this.formData.researchPaperUrl.split("/").pop()
      };
      await deleteResearchData(postData)
        .then(async (resp) => {
          if (resp.status === 200){
            this.handleSuccessAlert(this.researchDialog.successMessage);
            this.deleteDisabled = true;
            this.publishDisabled = false;
            window.open(`${process.env.VUE_APP_BASE_URL_NONP}`, "_blank");
          } else {
            this.handelApiError();
          }
        })
        .finally(() => {
          this.enableLoader = false;
        });
    },
    async publishResearch() {
      this.showResearchDialog = false;
      this.enableLoader = true;
      const postData = {
        publishType: 'delete',
        fileName: this.formData.researchPaperUrl.split("/").pop()
      };
      await publishResearchData(postData)
        .then(async (resp) => {
          if (resp.status === 201){
            this.handleSuccessAlert(this.researchDialog.successMessage);
            this.deleteDisabled = false;
            this.publishDisabled = true;
            window.open(window.location.protocol + "//" +window.location.host, "_blank");
            this.sendEmailAlert();
            this.getRecentResearch();
          } else {
            this.handelApiError();
          }
        })
        .finally(() => {
          this.enableLoader = false;
        });
    },
    handleSuccessAlert(alertMsg) {
      setTimeout(() => {
        this.alertData = {
          variation: 'success',
          title: alertMsg,
          delay: 6000,
        };
        this.showAlert = true;
      }, 100);
    },
    resetResearchDialog() {
      this.showResearchDialog = false;
    },
    onAlertDismiss() {
      this.showAlert = false;
    },
    async sendEmailAlert(){
      const emailBody = {
        "Research Article Name": this.formData.title.value,
        "Deleted Date": format(new Date(), "MMM dd, yyyy"),
        "Deleted By": this.userName
      }
      const emailPostData = {
        subject: this.emailConfig.articleDelSubject,
        toEmail: `${process.env.VUE_APP_RESEARCH_PUB_TO_EMAIL}`,
        fromEmail: `${process.env.VUE_APP_RESEARCH_PUB_FROM_EMAIL}`,
        emailContent: emailBody,
      }
      sendMail(emailPostData);
    },
    handelApiError() {
      this.alertData = {
        variation: "error",
        title: "Error",
        message: "It looks like something went wrong",
      };
      this.showAlert = true;
    }
  }
};
</script>

<style scoped lang="scss">
.research-form {
  &-container {
    max-width: 960px;
    padding: $mds-space-2-x;
    margin: 0 auto;
  }
  &-title{
    @include mds-level-3-heading;
    text-align: center;
  }
  &-note{
    @include mds-body-text-m()
  }
  &-input-field {
    margin: $mds-space-1-x 0;
    ::v-deep {
      .mds-label .mds-label__text {
        margin-bottom: $mds-space-1-x;
      }
    }
  }
  &-action-btn {
    padding: $mds-space-1-x;
  }
}
</style>
