<template>
  <div class="research-form-container">
    <lock-screen-loader v-if="enableLoader"/>
    <app-alert
      v-if="showAlert"
      :alertData="alertData"
      @dismissed="onAlertDismiss"
    />

    <mds-dialog
      v-model="showResearchDialog"
      :title="researchDialog.dialogTitle"
      :text="researchDialog.dialogText"
      @mds-dialog-dismissed="resetResearchDialog"
    >
      <template #mds-dialog-actions-right>
        <mds-button-container right-aligned>
          <mds-button
            type="button"
            variation="secondary"
            @click="handleDialogCancel"
          >
            {{ researchDialog.cancelBtnText }}
          </mds-button>
          <mds-button
            type="button"
            variation="primary"
            @click="handleDialogSuccess"
          >
            {{ researchDialog.successBtnText }}
          </mds-button>
        </mds-button-container>
      </template>
    </mds-dialog>

    <mds-layout-grid>
      <mds-row>
        <mds-col :cols="12">
          <div class="research-form-title">{{ formData.formTitle }}</div>
        </mds-col>
      </mds-row>

      <mds-row>
        <mds-col :cols="12" :cols-at-m="12" class="research-form-input-field">
          <mds-input v-bind="formData.title" @input="formData.title.error=false" v-model="formData.title.value" :disabled="isFormDisabled" />
        </mds-col>
      </mds-row>

      <mds-row>
        <mds-col :cols="12" :cols-at-m="12">
          <mds-fieldset class="research-form-input-field">
            <mds-select v-bind="formData.category" v-model="formData.category.value" :disabled="isFormDisabled" @input="formData.category.error=false" />
          </mds-fieldset>
        </mds-col>
      </mds-row>

      <mds-row>
        <mds-col :cols="12" :cols-at-m="12" class="research-form-input-field">
          <mds-textarea v-bind="formData.description" v-model="formData.description.value" :disabled="isFormDisabled" @input="formData.description.error=false" />
        </mds-col>
      </mds-row>

      <mds-row>
        <mds-col :cols="12" :cols-at-m="12" class="research-form-input-field">
          <mds-textarea v-bind="formData.introduction" v-model="formData.introduction.value" :disabled="isFormDisabled" @input="formData.introduction.error=false" />
        </mds-col>
      </mds-row>

      <mds-row>
        <mds-col :cols="12" :cols-at-m="12" class="research-form-input-field">
          <mds-textarea v-bind="formData.keyPoints" v-model="formData.keyPoints.value" :disabled="isFormDisabled" @input="formData.keyPoints.error=false" />
        </mds-col>
      </mds-row>

      <mds-row>
        <mds-col :cols="12" :cols-at-m="6" >
          <mds-fieldset class="research-form-input-field" >
            <mds-date-picker
              v-bind="formData.publishDate"
              v-model="formData.publishDate.value"
              :min-max-dates="{min: new Date(2000, 0, 1), max: new Date(2099, 8, 15)}"
              :date-format="{day:'2-digit', month: '2-digit'}"
              :disabled="isFormDisabled"
              onkeydown="return false"
              onpaste="return false"
              @input="formData.publishDate.error = false"
            ></mds-date-picker>
          </mds-fieldset>
        </mds-col>
        <mds-col :cols="12" :cols-at-m="6">
          <mds-fieldset class="research-form-input-field">
            <mds-select v-bind="formData.type" v-model="formData.type.value" :disabled="isFormDisabled" @input="formData.type.error=false"/>
          </mds-fieldset>
        </mds-col>
      </mds-row>

      <mds-row>
        <mds-col :cols="12" :cols-at-m="12" class="research-form-input-field">
          <mds-input
            v-bind="formData.researchFile"
            @change="selectFile"
            type="file"
            :disabled="isFormDisabled"
            @input="formData.researchFile.error = false"
          />
        </mds-col>
      </mds-row>

    </mds-layout-grid>

    <div class="research-form-note" v-html="this.formData.submitNoteMessage" />

    <mds-button-container right-aligned class="research-form-action-btn">
      <mds-button v-if="submitVisible && !isEditMode" variation="secondary" @click="resetForm"> Reset </mds-button>
      <mds-button variation="primary" v-if="submitVisible" @click="onSubmit" :disabled="isFormChanged" >Submit</mds-button>
      <mds-button variation="primary" v-if="editVisible" @click="onEdit" >Edit</mds-button>
      <mds-button variation="primary" :disabled="publishDisabled"  @click="onPublish">Publish To Prod</mds-button>
    </mds-button-container>
  </div>
</template>

<script>
import MdsDialog from '@mds/dialog';
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import { MdsButton, MdsButtonContainer } from "@mds/button";
import MdsInput from "@mds/input";
import MdsFieldset from "@mds/fieldset";
import MdsSelect from "@mds/select";
import MdsTextarea from "@mds/textarea";
import cloneDeep from 'lodash/cloneDeep';
import researchFormConfig from '@/data/publishResearch.json';
import MdsDatePicker from '@mds/date-picker'
import { format } from 'date-fns';
import LockScreenLoader from '@/components/Common/LockScreenLoader/LockScreenLoader.vue';
import AppAlert from '@/components/Common/AppAlert/AppAlert';
import {
  submitResearchData,
  editResearchData,
  publishResearchData,
  initiateUpload,
  uploadFileInChunk,
  completeUpload,
} from "@/services/researchService.js";
import { sendMail } from "@/services/emailService.js"
import { mapGetters } from 'vuex';

const MAX_FILE_SIZE_BYTES = 5 * 1024 * 1024; // 6MB
export default {
  name: "ResearchForm",
  components: {
    MdsDialog,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsInput,
    MdsButton,
    MdsButtonContainer,
    MdsFieldset,
    MdsSelect,
    MdsTextarea,
    MdsDatePicker,
    LockScreenLoader,
    AppAlert
  },
  data() {
    return {
      formData: cloneDeep(researchFormConfig?.formConfig),
      formDataCopy: {},
      file: null | File,
      fileName: '',
      submitVisible: true,
      publishDisabled: true,
      editVisible: false,
      enableLoader: false,
      isFormDisabled: false,
      isFileChanged: false,
      showAlert: false,
      alertData: {},
      dialogConfig: cloneDeep(researchFormConfig?.dialogConfig),
      researchDialog: {},
      showResearchDialog: false,
      isEditMode: false,
      emailConfig: cloneDeep(researchFormConfig?.emailConfig)
    };
  },
  computed: {
    ...mapGetters({
      userName: 'user/userName',
    }),
    isFormChanged() {
      return (
        JSON.stringify(this.formData) == JSON.stringify(this.formDataCopy) &&
        !this.isFileChanged
      );
    }
  },
  methods: {
    validateForm() {
      var isValid = true;
      if (this.$el.querySelector("#category").value === "") {
        this.formData.category.error = true;
        isValid = false;
      } else {
        this.formData.category.error = false;
      }
      if (this.$el.querySelector("#title").value === "") {
        this.formData.title.error = true;
        isValid = false;
      } else {
        this.formData.title.error = false;
      }
      if (this.$el.querySelector("#description").value === "") {
        this.formData.description.error = true;
        isValid = false;
      } else {
        this.formData.description.error = false;
      }
      if (this.$el.querySelector("#introduction").value === "") {
        this.formData.introduction.error = true;
        isValid = false;
      } else {
        this.formData.introduction.error = false;
      }
      if (this.$el.querySelector("#keyPoints").value === "") {
        this.formData.keyPoints.error = true;
        isValid = false;
      } else {
        this.formData.keyPoints.error = false;
      }
      if (this.formData.publishDate.value === "") {
        this.formData.publishDate.error = true;
        isValid = false;
      } else {
        this.formData.publishDate.error = false;
      }
      if (this.$el.querySelector("#type").value === "") {
        this.formData.type.error = true;
        isValid = false;
      } else {
        this.formData.type.error = false;
      }
      if (!this.isEditMode && this.file == 0) {
        this.formData.researchFile.error = true;
        isValid = false;
      } else {
        this.formData.researchFile.error = false;
      }
      return isValid;
    },
    selectFile: function (event) {
      this.file = event?.target?.files.length == 0 ? 0 : event?.target?.files[0];
      this.isFileChanged = true;
    },
    onSubmit(event) {
      if (this.validateForm()) {
        this.researchDialog = this.dialogConfig?.submitResearch;
        this.showResearchDialog = true;
      }
      event.preventDefault();
    },
    onPublish(event) {
      this.researchDialog = this.dialogConfig?.publishResearch;
      this.showResearchDialog = true;
      event.preventDefault();
    },
    onEdit(event){
      this.isFormDisabled = false;
      this.editVisible = false;
      this.submitVisible = true;
      this.isEditMode = true;
      this.formDataCopy = cloneDeep(this.formData);
      this.isFileChanged = false;
      event.preventDefault();
    },
    handleDialogCancel() {
      this.showResearchDialog = false;
    },
    handleDialogSuccess() {
      switch (this.researchDialog?.action) {
        case 'submit':
          this.submitResearch();
          break;
        case 'publish':
          this.publishResearch();
          break;
        default:
          break;
      }
    },
    async submitResearch(){
      this.showResearchDialog = false;
      this.enableLoader = true;
      const publishDateFormat = format(new Date(this.formData.publishDate.value), 'yyyy-MM-dd')
      const researchId = this.getResearchId();

      let researchData = {};
      researchData["id"] = researchId;
      researchData[this.formData.title.name] = this.formData.title.value;
      researchData[this.formData.description.name] = this.formData.description.value;
      researchData["status"] = "active";
      researchData[this.formData.introduction.name] = this.formData.introduction.value;
      researchData[this.formData.keyPoints.name] = this.formData.keyPoints.value?.split('\n');
      researchData["researchPaperUrl"] = `research-papers/${researchData.id}.pdf`;
      researchData[this.formData.publishDate.name] = publishDateFormat;
      researchData[this.formData.type.name] = this.formData.type.value;

      this.fileName = `${researchData.id}.pdf`
      let postData = new FormData();
      postData.append("researchData", JSON.stringify(researchData));
      if (this.isEditMode && this.formData.title.value == this.formDataCopy.title.value){
        this.editResearchApiCall(postData);
      } else {
        this.submitResearchApiCall(postData);
      }
    },

    async publishResearch() {
      this.showResearchDialog = false;
      this.enableLoader = true;
      let postData = {};
      await publishResearchData(postData)
        .then(async (resp) => {
          if (resp.status === 201) {
            this.handleSuccessAlert(this.researchDialog.successMessage);
            this.submitVisible = true;
            this.editVisible = false;
            this.isEditMode = false;
            this.publishDisabled = true;
            this.isFormDisabled = false;
            window.open(window.location.protocol + "//" +window.location.host, "_blank");
            this.sendEmailAlert();
            this.resetForm();
          } else {
            this.handelApiError()
          }
        })
        .finally(() => {
          this.enableLoader = false;
        })
    },
    async submitResearchApiCall(postData){
      if (this.file.size >= MAX_FILE_SIZE_BYTES) {
        await this.uploadFileInChunks();
      } else{
        postData.append("file", this.file, this.fileName);
      }
      await submitResearchData(postData)
        .then(async (resp) => {
          if (resp.status === 201) {
            this.handleSubmitSuccess()
          } else {
            this.handelApiError()
          }
        })
        .finally(() => {
          this.enableLoader = false;
        });
    },
    async editResearchApiCall(postData){
      if (this.file !== 0) {
        if (this.file.size >= MAX_FILE_SIZE_BYTES) {
          await this.uploadFileInChunks();
        } else{
          postData.append("file", this.file, this.fileName);
        }
      }
      await editResearchData(postData)
        .then(async (resp) => {
          if (resp.status === 201) {
            this.handleSubmitSuccess()
          } else {
            this.handelApiError()
          }
        })
        .finally(() => {
          this.enableLoader = false;
        });
    },
    async uploadFileInChunks() {
      initiateUpload(this.fileName).then(async (resp) => {
        if (resp?.uploadId) {
          const uploadId = resp?.uploadId
          const totalChunks = Math.ceil(this.file.size / MAX_FILE_SIZE_BYTES);
          const uploadPromises = [];
          let start = 0, end;
          for (let i = 0; i < totalChunks; i++) {
            end = start + MAX_FILE_SIZE_BYTES;
            const chunk = this.file.slice(start, end);
            const postData = new FormData();
            postData.append("index", i);
            postData.append("file", chunk);
            postData.append("uploadId", uploadId);
            const uploadPromise = uploadFileInChunk(this.fileName, postData);
            uploadPromises.push(uploadPromise);
            start = end;
          }

          Promise.all(uploadPromises).then((resp) =>{
            if (resp) {
              this.completeUpload(uploadId);
            }
          });
        } else {
          this.handelApiError();
        }
      })
    },
    async completeUpload(uploadId){
      const body = {
        uploadId: uploadId,
      }
      completeUpload(this.fileName, body).then((resp) => {
        if (resp) {
          return;
        } else {
          this.handelApiError();
        }
      })
    },
    handleSubmitSuccess(){
      this.handleSuccessAlert(this.researchDialog.successMessage);
      this.submitVisible = false;
      this.editVisible = true;
      this.publishDisabled = false;
      this.isFormDisabled = true;
      this.formDataCopy = cloneDeep(this.formData);
      window.open(`${process.env.VUE_APP_BASE_URL_NONP}`, "_blank");
    },
    handleSuccessAlert(alertMsg) {
      setTimeout(() => {
        this.alertData = {
          variation: 'success',
          title: alertMsg,
          delay: 6000,
        };
        this.showAlert = true;
      }, 100);
    },
    handelApiError() {
      this.alertData = {
        variation: "error",
        title: "Error",
        message: "It looks like something went wrong",
      };
      this.showAlert = true;
    },
    async sendEmailAlert(){
      const emailBody = {
        "Research Article Name": this.formData.title.value,
        "Publish Date": format(new Date(), "MMM dd, yyyy"),
        "Published By": this.userName
      }
      const emailPostData = {
        subject: this.emailConfig.articlePubSubject,
        toEmail: `${process.env.VUE_APP_RESEARCH_PUB_TO_EMAIL}`,
        fromEmail: `${process.env.VUE_APP_RESEARCH_PUB_FROM_EMAIL}`,
        emailContent: emailBody,
      }
      sendMail(emailPostData);
    },
    resetForm() {
      this.formData = cloneDeep(researchFormConfig?.formConfig);
      this.$el.querySelector("#researchFile").value = '';
      this.file = 0;
    },
    resetResearchDialog() {
      this.showResearchDialog = false;
    },
    onAlertDismiss() {
      this.showAlert = false;
    },
    getResearchId(){
      const selectedCategory = this.formData.category.value
      const titleFormatted = this.formData.title.value.toLowerCase().replace(/[^\w\s]/g, "").replace(/[\s_]+/g, "-");

      switch (selectedCategory) {
        case "boots-on-the-ground":
        case "cmbs-chronicle":
          return `morningstar-credit-${titleFormatted}`;
        case "global-credit-insights":
        case "sasb-spotlight":
          return titleFormatted;
        default:
          return `morningstar-credit-${titleFormatted}`;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.research-form {
  &-container {
    max-width: 960px;
    padding: $mds-space-2-x;
    margin: 0 auto;
  }
  &-title{
    @include mds-level-3-heading;
    text-align: center;
  }
  &-note{
    @include mds-body-text-m()
  }
  &-input-field {
    margin: $mds-space-1-x 0;
    ::v-deep {
      .mds-label .mds-label__text {
        margin-bottom: $mds-space-1-x;
      }
    }
  }
  &-action-btn {
    padding: $mds-space-1-x;
  }
}
</style>
