<template>
  <div>
    <mds-tabs alignment="center" size="large" class="research-tabs-section">
      <mds-tabs-item
        v-for="tab in tabs"
        class="research-tabs-section__item"
        :id="'research-tabs-section__item-'+tab.id"
        :class="{'research-tabs-section__item-disabled': tab.disabled}"
        :key="tab.id"
        :text="tab.label"
        :title="tab.label"
        :active="selectedTab.id === tab.id"
        :icon="tab.icon"
        @mds-tabs-item-active="onSelectedTab(tab)"
      >
        {{ tab.label }}
      </mds-tabs-item>
    </mds-tabs>
    <add-research v-if="selectedTab.component === 'add-research'" />
    <edit-research v-if="selectedTab.component === 'edit-research'" />
  </div>
</template>
<script lang="js">
import researchConfig from '@/data/publishResearch.json';
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';
import { findIndex } from 'lodash';
import { computed } from '@vue/composition-api';
import { MdsTabs, MdsTabsItem } from '@mds/tabs';
import AddResearch from '../components/PublishResearch/AddResearch.vue';
import EditResearch from '../components/PublishResearch/EditResearch.vue';

export default {
  name:'ResearchReport',
  components: {
    MdsTabs, 
    MdsTabsItem,
    AddResearch,
    EditResearch
  },
  data() {
    return {
      tabs: cloneDeep(researchConfig?.tabs),
      selectedTab: {},
    }
  },
  computed: {
    ...mapGetters({
      isAdminUser: 'user/isAdminUser',
    }),
  },
  mounted() {
    if (!this.isAdminUser) {
      this.redirectToTab('add-research');
    } else {
      this.redirectToTab('edit-research');
    }
  },
  provide() {
    return {
      selectedTab: computed(() => this.selectedTab.id),
    };
  },
  methods: {
    onSelectedTab(tab) {
      if (tab?.disabled) {
        return;
      }
      this.selectedTab = tab;
      // this.activeTabId = tab.id;
    },
    redirectToTab(tabId) {
      const currentIndex = findIndex(this.tabs, (tabItem) => tabItem.id === tabId);
      this.tabs[currentIndex].disabled = false;
      // tab.disabled = false;
      // this.$set(this.tabs, currentIndex, tab)
      this.onSelectedTab(this.tabs[currentIndex]);
    },
  },
  }
</script>

<style scoped lang="scss">
@import "@mds/constants";
@import "@mds/fonts";
@import "@mds/typography";
@import "@mds/link";

.research-tabs-section {
  position: sticky;
  z-index: 10;
  top: 50px;
  background-color: #fff;

  &__item-disabled {
    pointer-events: none;
    cursor: not-allowed !important;
    opacity: 0.4;
  }
  // ::v-deep {
  //   .mds-button {
  //     cursor: not-allowed !important;
  //   }
  // }
}
</style>
